import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'loket-list',
    pathMatch: 'full'
  },
  {
    path: 'loket-list',
    loadChildren: () => import('./pages/loket-list/loket-list.module').then( m => m.LoketListPageModule)
  },
  {
    path: 'loket/:id',
    loadChildren: () => import('./pages/loket/loket.module').then( m => m.LoketPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'pengambilan',
    loadChildren: () => import('./pages/pengambilan/pengambilan.module').then( m => m.PengambilanPageModule)
  },
  {
    path: 'pengumuman',
    loadChildren: () => import('./pages/pengumuman/pengumuman.module').then( m => m.PengumumanPageModule)
  },
  {
    path: 'modal-edit-loket',
    loadChildren: () => import('./pages/modal-edit-loket/modal-edit-loket.module').then( m => m.ModalEditLoketPageModule)
  },
  {
    path: 'modal-konfirmasi-pengambilan',
    loadChildren: () => import('./pages/modal-konfirmasi-pengambilan/modal-konfirmasi-pengambilan.module').then( m => m.ModalKonfirmasiPengambilanPageModule)
  },
  {
    path: 'pengaturan',
    loadChildren: () => import('./pages/pengaturan/pengaturan.module').then( m => m.PengaturanPageModule)
  },
  {
    path: 'update-text/:id',
    loadChildren: () => import('./pages/update-text/update-text.module').then( m => m.UpdateTextPageModule)
  },
  {
    path: 'update-kolom/:id',
    loadChildren: () => import('./pages/update-kolom/update-kolom.module').then( m => m.UpdateKolomPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
