import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Daftar Loket', url: '/loket-list', icon: 'id-card' },
    { title: 'Laporan Pelayanan', url: '/report', icon: 'folder' },
    { title: 'Pengaturan', url: '/pengaturan', icon: 'settings' },
    { title: 'Layar Pengambilan Tiket', url: '/pengambilan', icon: 'desktop' },
    { title: 'Layar Pengumuman Antrian', url: '/pengumuman', icon: 'desktop' },
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor() {}
}
